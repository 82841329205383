import { Box, Button, Divider } from '@mui/material';
import React from 'react';

class PDFViewer extends React.Component {
	openENPDF = () => {
		// Chemin relatif vers le PDF
		const pdfPath = process.env.PUBLIC_URL + '/assets/CarteEN.pdf';

		// Ouvrir le PDF dans une nouvelle fenêtre
		window.open(pdfPath);
	};

	openFRPDF = () => {
		// Chemin relatif vers le PDF
		const pdfPath = process.env.PUBLIC_URL + '/assets/CarteFR.pdf';

		// Ouvrir le PDF dans une nouvelle fenêtre
		window.open(pdfPath);
	};

	render() {
		return (
			<Box m={8} 	id='la-carte'>
				<Box
					mb={5}
					textAlign={'center'}>
					<Button
            sx={{ color: '#FFF', backgroundColor: '#462D24' }}
						onClick={this.openFRPDF}>
						Voir le menu
					</Button>
				</Box>
				<Divider />
				<Box
					mt={5}
					textAlign={'center'}>
					<Button
						sx={{ color: '#FFF', backgroundColor: '#462D24' }}
						variant='contained'
						onClick={this.openENPDF}>
						See the menu
					</Button>
				</Box>
			</Box>
		);
	}
}

export default PDFViewer;
