import Hero from './Hero/Hero';
import Presentation from './Presentation/Presentation';
import MenuPizza from './MenuPizza/MenuPizza';
import MenuHorsSaison from './MenuHorsSaison/MenuHorsSaison';
import Footer from './Footer/Footer';
import Menu from './Menu/Menu';
import PDFViewer from '../../components/PDFViewer/PDFViewer';

const Home = () => {
	return (
		<>
			<Hero />
			<Presentation />
		<PDFViewer />
			<Footer />
		</>
	);
};

export default Home;
