import { Box, Typography } from '@mui/material';
import img from '../../../assets/IMG_2791.jpeg';
import classes from './Presentation.module.css';

const Presentation = () => {
	return (
		<Box
			component='div'
			className={classes.containerPres}
			id="notre-restaurant">
			<Box sx={{ display: 'flex', alignItems: 'end' }}>
				<Typography variant='h2' sx={{fontSize: {xs: '40px', sm: '40px'}}}>L'ARAGONITE...</Typography>
				<Box className={classes.underline}></Box>
			</Box>
			<Box
				component='div'
				sx={{ display: 'flex' }}
				className={classes.presentation}>
				<Typography
					variant='body1'
					className={classes.txtpresent}>
					vous invite à déguster une cuisine traditionnelle simple et variée,
					aux couleurs des produits provençaux et aux mille saveurs
					méridionales. Rosario le chef de cuisine travaille sur le principe de
					l’Ardoise. Notre offre est volontairement plus réduite car elle
					implique des produits faits maison cuisinés sur place. Ce soin a été
					apporté à toutes les étapes de votre repas des entrées aux desserts
					ainsi que pour les menus enfants. C’est dans un cadre paisible en
					plein cœur d’un parc boisé de 6 hectares que vous êtes accueillis.<br />
					Déjeuner à L’Aragonite, c’est aussi imaginer les galeries, les
					stalactites et stalagmites millénaires, les concrétions formées goutte
					à goutte sur des roches calcaires,… juste sous nos pieds. En intérieur
					climatisé ou en terrasse ombragée, le restaurant l’Aragonite propose
					des formules pour tous.
				</Typography>
				<Box component='div'>
					<img
						src={img}
						className={classes.img}
						alt='img'
					/>
				</Box>
			</Box>
		</Box>
	);
};

export default Presentation;
