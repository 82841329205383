import React from 'react';
import './App.css';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import Home from './pages/Home';
import Navbar from './components/Navbar/Navbar';
import Menu from './pages/Home/Menu/Menu';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Home />
    </ThemeProvider>
    
  );
}

export default App;
