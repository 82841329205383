import { Box, Typography } from '@mui/material';
import classes from './Map.module.css';

const Map = () => {
	return (
		<>
			<Box>
				<Typography
					variant='h5'
					sx={{ color: '#F4A588', textAlign: {xs: 'center', sm: 'center', md: 'start'} }}>
					OÙ SOMMES-NOUS ?
				</Typography>
			</Box>
			<Box
				component='div'
				sx={{ mt: 4, textAlign: {xs: 'center', sm: 'center'}}}>
				<iframe
					src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.489665097133!2d6.808337376701612!3d43.65878505206882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12cc219ef9f2fd0f%3A0xbcdab37aacae9021!2sRESTAURANT%20L&#39;ARAGONITE!5e0!3m2!1sfr!2sfr!4v1686734517932!5m2!1sfr!2sfr'
					style={{ border: '0' }}
					className= {classes.map}
					allowFullScreen
					loading='lazy'
					referrerPolicy='no-referrer-when-downgrade'></iframe>
			</Box>
		</>
	);
};

export default Map;
