import { Box, Link, Typography } from '@mui/material';
import classes from './Info.module.css';

const Info = () => {
	return (
		<>
			<Box>
				<Typography
					variant='h5'
					sx={{ color: '#F4A588', textAlign: {xs: 'center', sm: 'center', md: 'start'}}}>
					CONTACTEZ NOUS
				</Typography>
			</Box>
			<Box>
				<Box
					className={classes.info}
					sx={{ mt: 4 }}>
					<Typography
						variant='body1'
						sx={{ fontWeight: 'bold', mr:{ xs: '', md: 10 }  }}>
						Téléphone
					</Typography>
					<Typography className={classes.policeInfo}>04 92 60 25 82</Typography>
				</Box>
				<Box className={classes.info}>
					<Typography
						variant='body1'
						sx={{ fontWeight: 'bold', mr: {xs:'' ,md:'102px'} }}>
						Courriel
					</Typography>
					<Typography  className={classes.policeInfo}>
						<Link href='mailto:aragonite06530@gmail.com' sx={{textDecoration:'none'}}>
							aragonite06530@gmail.com
						</Link>
					</Typography>
				</Box>
				<Box className={classes.info}>
					<Typography
						variant='body1'
						sx={{ fontWeight: 'bold', mr: {xs:'' ,md:'101px'} }}>
						Adresse
					</Typography>
					<Box>
						<Typography  className={classes.policeInfo} sx={{textAlign:{xs:'end', sm:'end', md:'start' }}}>1481 route des Grottes,</Typography>
						<Typography  className={classes.policeInfo}>
							06530 Saint-Cézaire-sur-Siagne
						</Typography>
					</Box>
				</Box>
				<Box className={classes.info}>
					<Typography
						variant='body1'
						sx={{ fontWeight: 'bold', mr: {xs:'39px' ,md:'110px'} }}>
						Horaire
					</Typography>
					<Box>
						<Typography className={classes.policeInfo}>Lun-Vend: 10h - 16h</Typography>
						<Typography className={classes.policeInfo}>Dimanche: 10h - 16h</Typography>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default Info;
