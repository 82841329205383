import { createTheme } from '@mui/material';

const theme = createTheme({
	palette: {
		primary: {
			main: '#FFFFFF',
		},
	},
	typography: {
		fontFamily: 'Arvo, sans-serif',
		h1: {
			fontSize: '3rem',
			fontWeight: 700,
			lineHeight: 1.2,
			letterSpacing: '0.5rem',
		},
		h2: {
			fontSize: '48px',
			fontWeight: 700,
			lineHeight: 1.2,
		},
		h3: {
			fontSize: '2rem',
			fontWeight: 700,
			lineHeight: 1.2,
			letterSpacing: '0.5rem',
		},
		h4: {
			fontSize: '20px',
			fontWeight: 700,
			lineHeight: '42px',
		},
		h5: {
			fontSize: '32px',
			fontWeight: 700,
			lineHeight: '42px',
		},
		h6: {
			fontSize: '24px',
			fontWeight: 700,
			lineHeight: '42px',
		},
		subtitle1: {
			fontFamily: 'Montserrat, sans-serif',
			fontSize: '16px',
			fontStyle: 'italic',
			fontWeight: 400,
			lineHeight: 1.2,
		},
		subtitle2: {
			fontSize: '2rem',
			fontWeight: 700,
			lineHeight: 1.2,
		},

		body1: {
			fontFamily: 'Montserrat, sans-serif',
			fontSize: '20px',
			fontWeight: 400,
			lineHeight: '42px',
		},
		body2: {
			fontSize: '0.8rem',
			fontWeight: 400,
			lineHeight: 1.2,
			letterSpacing: '0.5rem',
		},
	
		
	},
});

export default theme;
