import { Box, Link, Typography } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';
import classes from './ReseauSociaux.module.css';

const ReseauSociaux = () => {
	return (
		<>
			<Box>
				<Typography
					variant='h5'
					sx={{ color: '#F4A588' , textAlign: {xs: 'center', sm: 'center', md: 'start'}}}>
					SUIVEZ NOUS
				</Typography>
				<Box sx={{display: {xs: 'flex', sm:'flex', md:'block'}, justifyContent: {xs: 'center', sm: 'center'}, my: {xs: 4, sm: 4}}}>
				<Box component='div' sx={{ mt: {xs: 'none', sm:'none', md:4} }} >
					<Link href='https://www.instagram.com/restaurantaragonite?igsh=OWprb2FycmFicmwy&utm_source=qr' target="_blank" sx={{display:'flex', alignItems:'center', textDecoration:'none'}}>
						<Instagram sx={{ color: '#ffffff', mr: 2}}  fontSize='large'  />
						<Typography component='span' sx={{display: {xs: 'none', sm:'none', md:'block'}}} className={classes.police}>Instagram</Typography>
					</Link>
				</Box>
				<Box  component='div'>
					<Link href='https://www.facebook.com/people/Restaurant-lAragonite/61552127035708/' target="_blank" sx={{display:'flex', alignItems:'center', textDecoration:'none'}}>
						<Facebook sx={{ color: '#ffffff', mr: 2 }} fontSize='large'/>
						<Typography component='span' sx={{display: {xs: 'none', sm:'none', md:'block'}}}className={classes.police}>Facebook</Typography>
					</Link>
				</Box>
				</Box>
			</Box>
		</>
	);
};

export default ReseauSociaux;
