import React from 'react';
import {
	AppBar,
	Box,
	Button,
	Container,
	CssBaseline,
	Divider,
	Drawer,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Toolbar,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/system';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/logo-aragonite-blanc.png';
import { Facebook, Instagram } from '@mui/icons-material';

interface Props {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window?: () => Window;
}

const drawerWidth = 350;
const navItems = ['LA CARTE', 'NOTRE RESTAURANT', 'CONTACTEZ NOUS'];

const Navbar = (props: Props) => {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen((prevState) => !prevState);
	};

	const drawer = (
		<Box
			onClick={handleDrawerToggle}
			sx={{ textAlign: 'center' }}>
			<Typography
				variant='h6'
				sx={{ my: 2 }}>
				<img
					src={logo}
					alt='logo'
					width='150'
				/>
			</Typography>
			<Divider sx={{backgroundColor: 'white', mx: 5}} />
			<List>
				{navItems.map((item) => (
					<ListItem
						key={item}
						disablePadding>
						<ListItemButton sx={{ textAlign: 'center' }}>
							<ListItemText>
							<Link
											href={`#${item.toLowerCase().replace(/\s+/g, '-')}`}
											sx={{ textDecoration: 'none' }}>
											{item}
										</Link>
							</ListItemText>
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<Divider sx={{backgroundColor: 'white', mx: 5}} />
			<Box sx={{display:'flex', justifyContent:'center', mt: 5}}>
				<Box
					component='div'
					>
					<Link
						href='https://www.instagram.com/restaurantaragonite?igsh=OWprb2FycmFicmwy&utm_source=qr'
						target='_blank'
						sx={{
							display: 'flex',
							alignItems: 'center',
							textDecoration: 'none',
						}}>
						<Instagram
							sx={{ color: '#ffffff', mr: 2 }}
							fontSize='large'
						/>
					</Link>
				</Box>
				<Box component='div'>
					<Link
						href='https://www.facebook.com/people/Restaurant-lAragonite/61552127035708/'
						target='_blank'
						sx={{
							display: 'flex',
							alignItems: 'center',
							textDecoration: 'none',
						}}>
						<Facebook
							sx={{ color: '#ffffff', mr: 2 }}
							fontSize='large'
						/>
					</Link>
				</Box>
			</Box>
		</Box>
	);

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar
				sx={{
					position: 'absolute',
					backgroundColor: 'transparent',
					boxShadow: 'none',
				}}>
				<Container maxWidth='xl'>
					<Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
						<IconButton
							aria-label='open drawer'
							edge='end'
							onClick={handleDrawerToggle}
							sx={{ mr: 2, color: 'white',display: { md: 'none' } }}>
							<MenuIcon fontSize='large' />
						</IconButton>
						<Typography
							variant='h6'
							component='div'
							sx={{
								flexGrow:{ xs: 'none', sm: 'none', md: 1 },
								display: { xs: 'block', sm: 'block', md: 'block' },
								mt: 2,
							}}>
							<Link href='#'>
								<img
									src={logo}
									alt='logo'
									width={isMobile ? '130' : '200'}
								/>
							</Link>
						</Typography>
						<Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
							{navItems.map((item) => (
								<Button
									key={item}
									sx={{ color: '#fff' }}>
									<Typography variant='h4'>
										<Link
											href={`#${item.toLowerCase().replace(/\s+/g, '-')}`}
											sx={{ textDecoration: 'none' }}>
											{item}
										</Link>
									</Typography>
								</Button>
							))}
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<Box component='nav'>
				<Drawer
					container={container}
					variant='temporary'
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'block' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
							backgroundColor: '#462D24',
							color: 'white',
						},
					}}>
					{drawer}
				</Drawer>
			</Box>
		</Box>
	);
};

export default Navbar;
